<template>
	<v-card
		elevation="0"
		:height="userFormHeight"
		:loading="loadingData"
		class="ma-0 pa-0 ml-n1 mr-n1 overflow-y-auto"
	>
		<v-card-text class="pa-0 ma-0">
			<v-simple-table
				:height="tableHeight"
				ref="simpleTableApplicant"
			>
				<template v-slot:default>
					<v-row dense no-gutters style="overflow: hidden;">
						<v-col cols="12">
							<v-combobox
								v-model="search"
								ref="searchApplicants"
								clearable
								label="Search applicant"
								placeholder="Start typing to search"
								no-filter
								hide-no-data
								:items="applicantsOption"
								item-text="name"
								item-value="name"
								:loading="isSearching"
								:search-input.sync="searchApplicant"
								:no-data-text="searchNoData"
								@change="searchChange"
								@focus="searchOn = true;"
								@keydown.enter="searchEnter"
							></v-combobox>
						</v-col>
						
						<v-col
							cols="12" sm="6" md="6" lg="4" xl="3"
							v-if="!loadingData && profiles.length == 0"
						>
							No records found
						</v-col>
						
						<v-col
							cols="12" sm="6" md="6" lg="4" xl="3"
							v-for="item in profiles"
							:key="item.USERID"
						>
							<v-card
								outlined
								class="ma-1 primary"
								@click="viewDetails(item.USERID)"
							>
								<v-icon
									small
									dark
									:color="item.VERIFIED_BY > 0? 'yellow' : ''"
									class="ma-2"
									style="float:right;"
								>
									mdi-star
								</v-icon>
									
								<v-list-item three-line>
									<v-list-item-avatar
										size="80"
										color="grey"
										rounded="circle"
									>
										<v-img :src="item.image"></v-img>
									</v-list-item-avatar>
									
									<v-list-item-content>
										<v-list-item-title class="text-body-1 white--text">
											{{ item.name }}
											
											<div class="text-body-2">
												{{ item.rankdesc }}
											</div>
										</v-list-item-title>
										
										<v-list-item-subtitle class=" white--text">
											{{ item.status }}<br/>
											{{ item.appstatus }}&nbsp;
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-col>
					</v-row>
				</template>
			</v-simple-table>
					
			<v-row dense no-gutters style="overflow: hidden;">
				<v-col 
					cols="12" class="ml-1"
					v-if="records > 0"
				>
					Record 
					{{ (page == 1)? page : (itemsPerPage*page) - itemsPerPage }} - 
					{{ ( (itemsPerPage*page) > records )? records : itemsPerPage*page }} of
					{{ records }} Result
				</v-col>
				
				<v-col cols="12" class="text-center">
					<v-pagination
						v-model="page"
						circle
						:length="totalPage"
						:total-visible="$vuetify.breakpoint.xsOnly? 6 : 9"
						@input="getApplicants"
						@next="getApplicants"
						@previous="getApplicants"
					></v-pagination>
				</v-col>
			</v-row>
				
		</v-card-text>
		
		<v-dialog
			v-model="dialog"
			:fullscreen="!loadingVerified"
			scrollable
			max-width="500"
			:persistent="loadingVerified"
		>
			<v-card
				v-if="!loadingVerified"
				class="ma-0 pa-0"
			>
				<v-card-text
					class="headline pt-5 px-0 px-sm-0 px-md-5 px-lg-5 px-xl-5"
				>
					<v-row no-gutters class="mb-2">
						<v-col cols="12" class="text-center">
							<v-btn icon
								style="float: right;"
								@click="dialog = false"
							>
								<v-icon small dark>mdi-close</v-icon>
							</v-btn>
							<v-card
								tile
								elevation="5"
								:max-width="$vuetify.breakpoint.mdAndUp ? 150 : 100"
								:min-height="$vuetify.breakpoint.mdAndUp ? 150 : 100"
								class="mx-auto rounded-circle">
								
								<v-img
									:src="imageProfile"
									width="$vuetify.breakpoint.mdAndUp ? 150 : 100"
									height="$vuetify.breakpoint.mdAndUp ? 150 : 100"
									class="mx-auto"
								></v-img>
							</v-card>
						</v-col>
						
						<v-col cols="12" class="text-center">
							<v-list-item>
								<v-list-item-content class="pt-0 pb-0">
								<v-list-item-title class="title">{{ name }}</v-list-item-title>
								<v-list-item-subtitle>{{ rank }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1">
							PERSONAL INFORMATION
						</v-col>
						
						<v-col cols="12">
							<v-row dense no-gutters class="px-4 mt-3">
								<v-col
									v-for="item in basicInfoField"
									:key="item.key"
									cols="12" sm="6" md="3" lg="3" xl="3"
									class="text-center pt-0"
								>
									<v-text-field
										dense
										:value="basicInfo[item.key]"
										:label="item.label"
										disabled
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							CONTACT INFORMATION
						</v-col>
						
						<v-col cols="12">
							<v-row dense no-gutters class="px-4 mt-3">
								<v-col cols="12" sm="12" md="12" lg="6" xl="6" class="text-center pt-0">
									<v-text-field
										dense
										:value="typeof contactInfo.MAIN_ADDR1 !== 'undefined'? contactInfo.MAIN_ADDR1+' '+contactInfo.MAIN_ADDR2+' '+contactInfo.MAIN_ADDR3 : ''"
										label="Primary Address"
										disabled
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="12" md="12" lg="6" xl="6" class="text-center pt-0">
									<v-text-field
										dense
										:value="typeof contactInfo.SECONDARY_ADDR1 !== 'undefined'? contactInfo.SECONDARY_ADDR1+' '+contactInfo.SECONDARY_ADDR2+' '+contactInfo.SECONDARY_ADDR3 : ''"
										label="Secondary Address"
										disabled
									></v-text-field>
								</v-col>
								
								<v-col
									v-for="item in contactInfoField"
									:key="item.key"
									cols="12" sm="6" md="3" lg="3" xl="3"
									class="text-center pt-0"
								>
									<v-text-field
										dense
										:value="contactInfo[item.key]"
										:label="item.label"
										disabled
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							EDUCATION
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="education.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>LEVEL</th>
											<th>SCHOOL NAME</th>
											<th>COURSE</th>
											<th class="text-center">START YEAR</th>
											<th class="text-center">END YEAR</th>
										</tr>
									</thead>
									
									<tbody>
										<tr
											v-for="item in education"
											:key="item.EDUC_LEVEL"
										>
											<td>{{ item.EDUC_LEVEL }}</td>
											<td>{{ item.SCHOOL }}</td>
											<td>{{ item.EDUC_COURSE }}</td>
											<td class="text-center">{{ item.EDUC_START }}</td>
											<td class="text-center">{{ item.EDUC_FINISH }}</td>
										</tr>
										
										<tr v-if="education.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>	
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="education.length > 5? '250px' : 'undefined'"
								v-else
							>	
								<template v-slot:default>
									<thead>
										<tr>
											<th>LEVEL<br/>SCHOOL NAME</th>
											<th class="text-center">START YEAR<br/>END YEAR</th>
										</tr>
									</thead>
									
									<tbody>
										<tr
											v-for="item in education"
											:key="item.EDUC_LEVEL"
										>
											<td>{{ item.EDUC_LEVEL }}<br/>{{ item.SCHOOL }}</td>
											<td class="text-center">{{ item.EDUC_START }}<br/>{{ item.EDUC_FINISH }}</td>
										</tr>
										
										<tr v-if="education.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							FAMILY
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="family.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>NAME</th>
											<th>RELATIONSHIP</th>
											<th class="text-center">DATE OF BIRTH</th>
											<th>PLACE OF BIRTH</th>
											<th class="text-center">BENEFICIARY</th>
											<th class="text-center">DEPENDENT</th>
											<th class="text-center">CONTACT PERSON</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in family"
											:key="item.NAME"
										>
											<td>{{ item.NAME }}</td>
											<td>{{ item.RELATIONSHIP }}</td>
											<td class="text-center">{{ item.BDAY }}</td>
											<td>{{ item.POB }}</td>
											<td class="text-center">
												<v-icon v-if="item.BENEFACTOR == 1">mdi-check</v-icon>
												<v-icon v-else>mdi-close</v-icon>
											</td>
											<td class="text-center">
												<v-icon v-if="item.DEPENDENT == 1">mdi-check</v-icon>
												<v-icon v-else>mdi-close</v-icon>
											</td>
											<td class="text-center">
												<v-icon v-if="item.CONTACT_PERSON == 1">mdi-check</v-icon>
												<v-icon v-else>mdi-close</v-icon>
											</td>
										</tr>
										
										<tr v-if="family.length == 0">
											<td colspan="7">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="family.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>NAME<br/>RELATIONSHIP</th>
											<th>DATE OF BIRTH<br/>PLACE OF BIRTH</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in family"
											:key="item.NAME"
										>
											<td>{{ item.NAME }}<br/>{{ item.RELATIONSHIP }}</td>
											<td>{{ item.BDAY }}<br/>{{ item.POB }}</td>
										</tr>
										
										<tr v-if="family.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							EMPLOYMENT
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="employment.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>AGENCY</th>
											<th>VESSEL NAME</th>
											<th class="text-center">RANK</th>
											<th>VESSEL TYPE</th>
											<th class="text-center">SIGN ON</th>
											<th class="text-center">SIGN OFF</th>
											<th class="text-center">TERMINATION CAUSE</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in employment"
											:key="item.NAME"
										>
											<td>{{ item.AGENCY }}</td>
											<td>{{ item.H_VSSLNAME }}</td>
											<td class="text-center">{{ item.H_RANKCODE }}</td>
											<td>{{ item.H_VSSLTYPE }}</td>
											<td class="text-center">{{ item.H_SIGNONDATE }}</td>
											<td class="text-center">{{ item.H_SIGNOFDATE }}</td>
											<td class="text-center">{{ item.TERMDESC }}</td>
										</tr>
										
										<tr v-if="employment.length == 0">
											<td colspan="7">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="employment.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>AGENCY<br/>VESSEL NAME</th>
											<th class="text-center">RANK<br/>VESSEL TYPE</th>
											<th class="text-center">SIGN ON<br/>SIGN OFF</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in employment"
											:key="item.NAME"
										>
											<td>{{ item.AGENCY }}<br/>{{ item.H_VSSLNAME }}</td>
											<td class="text-center">{{ item.H_RANKCODE }}<br/>{{ item.H_VSSLTYPE }}</td>
											<td class="text-center">{{ item.H_SIGNONDATE }}<br/>{{ item.H_SIGNOFDATE }}</td>
										</tr>
										
										<tr v-if="employment.length == 0">
											<td colspan="3">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							LOCAL DOCUMENT
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="localDocs.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th>PLACE OF ISSUED</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in localDocs"
											:key="item.DOC_NAME"
										>
											<td>{{ item.DOC_NAME }}</td>
											<td>{{ item.DOC_NUMBER }}</td>
											<td>{{ item.PLACE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="localDocs.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="localDocs.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in localDocs"
											:key="item.DOC_NAME"
										>
											<td>{{ item.DOC_NAME }}<br/>{{ item.DOC_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="localDocs.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							FOREIGN DOCUMENT
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="foreignDocs.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th>PLACE OF ISSUED</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in foreignDocs"
											:key="item.FOREIGN_DOC_NAME"
										>
											<td>{{ item.FOREIGN_DOC_NAME }}</td>
											<td>{{ item.FDOC_NUMBER }}</td>
											<td>{{ item.PLACE }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="foreignDocs.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="foreignDocs.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in foreignDocs"
											:key="item.FOREIGN_DOC_NAME"
										>
											<td>{{ item.FOREIGN_DOC_NAME }}<br/>{{ item.FDOC_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="foreignDocs.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							TRAININGS
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="training.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>TRAINING NAME</th>
											<th>CERTIFICATE NUMBER</th>
											<th>TRAINING CENTER</th>
											<th class="text-center">DATE TAKEN</th>
											<th class="text-center">DATE FINISH</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in training"
											:key="item.TRNG_NAME"
										>
											<td>{{ item.TRNG_NAME }}</td>
											<td>{{ item.TRNG_CERTNUMB }}</td>
											<td>{{ item.TRNG_CENTER }}</td>
											<td class="text-center">{{ item.TRNG_DATETAKEN }}</td>
											<td class="text-center">{{ item.TRNG_DATE_FIN }}</td>
											<td class="text-center">{{ item.TRNG_DATEISSUED }}</td>
											<td class="text-center">{{ item.TRNG_DATE_VALID }}</td>
										</tr>
										
										<tr v-if="training.length == 0">
											<td colspan="7">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="training.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>TRAINING NAME<br/>CERTIFICATE NUMBER</th>
											<th class="text-center">DATE TAKEN<br/>DATE FINISH</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in training"
											:key="item.TRNG_NAME"
										>
											<td>{{ item.TRNG_NAME }}<br/>{{ item.TRNG_CERTNUMB }}</td>
											<td class="text-center">{{ item.TRNG_DATETAKEN }}<br/>{{ item.TRNG_DATE_FIN }}</td>
											<td class="text-center">{{ item.TRNG_DATEISSUED }}<br/>{{ item.TRNG_DATE_VALID }}</td>
										</tr>
										
										<tr v-if="training.length == 0">
											<td colspan="3">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							CERTIFICATES
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="certificates.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>CERTIFICATE NAME</th>
											<th>CERTIFICATE NUMBER</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in certificates"
											:key="item.CERT_NAME"
										>
											<td>{{ item.CERT_NAME }}</td>
											<td>{{ item.CERT_NUMB }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="certificates.length == 0">
											<td colspan="4">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="certificates.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>CERTIFICATE NAME<br/>CERTIFICATE NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in certificates"
											:key="item.CERT_NAME"
										>
											<td>{{ item.CERT_NAME }}<br/>{{ item.CERT_NUMB }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="certificates.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
						<v-col cols="12" class="pl-4 font-weight-medium text-body-1 mt-5">
							VISA
						</v-col>
						
						<v-col cols="12">
							<v-simple-table
								dense
								fixed-header
								:height="visa.length > 5? '200px' : 'undefined'"
								v-if="$vuetify.breakpoint.smAndUp"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME</th>
											<th>DOCUMENT NUMBER</th>
											<th class="text-center">DATE APPLIED</th>
											<th class="text-center">DATE ISSUED</th>
											<th class="text-center">DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in visa"
											:key="item.VISANAME"
										>
											<td>{{ item.VISANAME }}</td>
											<td>{{ item.VISA_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_APPLIED }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}</td>
											<td class="text-center">{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="visa.length == 0">
											<td colspan="5">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-simple-table
								dense
								fixed-header
								:height="visa.length > 5? '250px' : 'undefined'"
								v-else
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th>DOCUMENT NAME<br/>DOCUMENT NUMBER</th>
											<th class="text-center">DATE ISSUED<br/>DATE VALID</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in visa"
											:key="item.VISANAME"
										>
											<td>{{ item.VISANAME }}<br/>{{ item.VISA_NUMBER }}</td>
											<td class="text-center">{{ item.DATE_ISSUED }}<br/>{{ item.DATE_VALID }}</td>
										</tr>
										
										<tr v-if="visa.length == 0">
											<td colspan="2">No records found</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						
					</v-row>
				</v-card-text>
				
				<v-card-actions v-if="isVerified == 0">
					<v-btn
						color="blue darken-1"
						text class="mx-auto"
						@click="verifiedApplicant"
					>
						VERIFY
					</v-btn>
				</v-card-actions>
			</v-card>
			
			<v-card
				color="primary"
				dark
				v-if="loadingVerified && !errorVerified"
			>
				<v-card-text class="pt-2">
					Saving...
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
			
			<v-card
				v-if="errorVerified"
			>	
				<v-card-title class="red darken-1 white--text">Verify Error</v-card-title>
				
				<v-divider></v-divider>
				
				<v-card-text class="pt-5">
					Please contact I.T. department to resolve {{ errorVerifiedMsg }} issues.
				</v-card-text>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="dialog = false; errorVerified = false;"
					>
						Close
					</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>

import { mapGetters } from 'vuex';

var timeoutSearch = '';
var timeoutSearchEnter = '';

var timeoutTableResize	= null;
var timeoutWindowResize	= null;

export default {
	data () {
		return {
			page: 1,
			itemsPerPage: 12,
			totalPage: 0,
			records: 0,
			
			tableHeight			: 'undefined',
			
			searchOn: false,
			isSearching: false,
			searchApplicant: null,
			applicantsOption: [],
			searchNoData: 'Searching...',
			search: '',
			
			profiles: [],
			
			dialog: false,
			loadingData: false,
			loadingVerified: false,
			errorVerified: false,
			errorVerifiedMsg: '',
			
			imageProfile: '',
			name: '',
			rank: '',
			id: 0,
			isVerified: 0,
			
			basicInfo: [],
			basicInfoField	: [
				{ label: 'Birthdate',		key: 'DOB'},
				{ label: 'Place of Birth',	key: 'POB'},
				{ label: 'Gender',			key: 'GENDER'},
				{ label: 'Civil Status',	key: 'CIVIL_STATUS'},
				{ label: 'Nationality',		key: 'NATIONALITY'},
				{ label: 'Religion',		key: 'RELIGION'},
				{ label: 'Height',			key: 'HEIGHT'},
				{ label: 'Weight',			key: 'WEIGHT'},
				{ label: 'Color of Eyes',	key: 'EYECOLOR'},
				{ label: 'Color of Hair',	key: 'HAIRCOLOR'},
				{ label: 'Shoe Size',		key: 'SHOE_SIZE'},
				{ label: 'Cover All Size',	key: 'COVERALL_SIZE'},
				{ label: 'SSS ID Number',	key: 'SSS'},
				{ label: 'PAG-IBIG (HDMF) ID Number',	key: 'PAG_IBIG'},
				{ label: 'TIN ID Number',	key: 'TIN'},
				{ label: 'PhilHealth ID Number',		key: 'PHILHEALTH'}
			],
			contactInfo			: [],
			contactInfoField	: [
				{ label: 'Mobile Number',	key: 'MOBILE'},
				{ label: 'Secondary Mobile Number',		key: 'SECONDARY_MOBILE'},
				{ label: 'Telephone',		key: 'TELEPHONE'},
				{ label: 'Email Address',	key: 'EMAIL'}
			],
			
			education			: [],
			family				: [],
			employment			: [],
			localDocs			: [],
			foreignDocs			: [],
			training			: [],
			certificates		: [],
			visa				: [],
		}
	},
	methods: {
		searchChange(){
			if( typeof this.search.name !== 'undefined' )this.search = this.search.name;
			
			clearTimeout(timeoutSearchEnter);
			timeoutSearchEnter = setTimeout(() => { this.getApplicants(); }, 5);
		},
		searchEnter(){
			this.$refs.searchApplicants.blur();
			clearTimeout(timeoutSearchEnter);
			timeoutSearchEnter = setTimeout(() => { this.getApplicants(); }, 5);
		},
		getApplicants () {
			this.loadingData = true;
			
			this.$http.get('applicants',{ params:{ page: this.page, items: this.itemsPerPage, search: this.search.trim() } }).then((resp) => {
				this.profiles = resp.data.data;
				
				var num = resp.data.total/this.itemsPerPage;
				var arr = num.toString().split('.');
				
				this.records = resp.data.total;
				this.totalPage = (arr.length > 1)? (arr[0]-0)+1 : arr[0]-0;
				
			})
			.finally(() => {
				this.loadingData = false;
				
				this.tableResize();
			});
		},
		tableResize(){
			clearTimeout(timeoutTableResize);
			timeoutTableResize = setTimeout(() => {
				if(typeof this.$refs.simpleTableApplicant !== 'undefined'){
					const windowWidth = this.$refs.simpleTableApplicant.$parent.$el.clientWidth;
					const tableWidth = this.$refs.simpleTableApplicant.$el.children[0].children[0].clientWidth;
					const scrollX = ( tableWidth > windowWidth)? 20 : 0;
					
					const tbodyHeight= this.$refs.simpleTableApplicant.$el.children[0].children[0].clientHeight;
					this.tableHeight = ( (this.userFormHeight - (55 + 65) ) > tbodyHeight )? tbodyHeight : this.userFormHeight - (55 + 65);
					this.tableHeight += scrollX;
				}
			}, 50);
		},
		viewDetails(userid){
			
			if(userid < 1)return
			
			this.id = userid;
			this.name = this.profiles.find(({ USERID }) => USERID === userid).name;
			this.rank = this.profiles.find(({ USERID }) => USERID === userid).rankdesc;
			this.isVerified = this.profiles.find(({ USERID }) => USERID === userid).VERIFIED_BY;
			this.imageProfile = this.profiles.find(({ USERID }) => USERID === userid).image;
			
			this.dialog = true;
			
			this.$http.get('profile',{ params:{ id: userid } }).then((resp) => {
				if(resp.data.SSS && resp.data.SSS != null){
					const sss = resp.data.SSS.replace(/[^\d]/g, "");
					resp.data.SSS = (sss != '')? sss.substr(0, 2)+'-'+sss.substr(2, 7)+'-'+sss.substr(9, 1) : '';
				}else{
					resp.data.SSS = '';
				}
				
				if(resp.data.PAG_IBIG && resp.data.PAG_IBIG != null){
					const hdmf = resp.data.PAG_IBIG.replace(/[^\d]/g, "");
					resp.data.PAG_IBIG = (hdmf != '')? hdmf.substr(0, 4)+'-'+hdmf.substr(4, 4)+'-'+hdmf.substr(8, 4) : '';
				}else{
					resp.data.PAG_IBIG = '';
				}

				if(resp.data.TIN && resp.data.TIN != null){
					const tin = resp.data.TIN.replace(/[^\d]/g, "");
					resp.data.TIN = (tin != '')? tin.substr(0, 3)+'-'+tin.substr(3, 3)+'-'+tin.substr(6, 3) : '';
				}else{
					resp.data.TIN = '';
				}

				if(resp.data.PHILHEALTH && resp.data.PHILHEALTH != null){
					const philHealth = resp.data.PHILHEALTH.replace(/[^\d]/g, "");
					resp.data.PHILHEALTH = (philHealth != '')? philHealth.substr(0, 2)+'-'+philHealth.substr(2, 9)+'-'+philHealth.substr(11, 1) : '';
				}else{
					resp.data.PHILHEALTH = '';
				}
				
				if(!this.isValidDate(resp.data.DOB))resp.data.DOB = '';
				
				this.basicInfo = resp.data;
			});
			
			this.$http.get('contact',{ params:{ id: userid } })
			.then((resp) => {
				if(resp.data.MOBILE && resp.data.MOBILE != null){
					const mobile = resp.data.MOBILE.replace(/[^\d]/g, "");
					resp.data.MOBILE = (mobile != '')? mobile.substr(0, 4)+'-'+mobile.substr(4, 4)+'-'+mobile.substr(8, 3) : '';
				}else{
					resp.data.MOBILE = '';
				}
				
				if(resp.data.SECONDARY_MOBILE && resp.data.SECONDARY_MOBILE != null){
					const mobile2 = resp.data.SECONDARY_MOBILE.replace(/[^\d]/g, "");
					resp.data.SECONDARY_MOBILE = (mobile2 != '')? mobile2.substr(0, 4)+'-'+mobile2.substr(4, 4)+'-'+mobile2.substr(8, 3) : '';
				}else{
					resp.data.SECONDARY_MOBILE = '';
				}
				
				if(resp.data.TELEPHONE && resp.data.TELEPHONE != null){
					const telephone = resp.data.TELEPHONE.replace(/[^\d]/g, "");
					resp.data.TELEPHONE = (telephone != '')? telephone.substr(0, 2)+'-'+telephone.substr(2, 4)+'-'+telephone.substr(6, 4) : '';
				}else{
					resp.data.TELEPHONE = '';
				}
				
				this.contactInfo = resp.data;
			});
			
			Promise.all([
				this.getEducation(),
				this.getFamily(),
				this.getEmployment(),
				this.getLocaldocs(),
				this.getForeigndocs(),
				this.getTraining(),
				this.getCertificate(),
				this.getVisa()
			])
			.then((response) => {
				this.education		= response[0].data;
				this.family			= response[1].data;
				this.employment		= response[2].data;
				this.localDocs		= response[3].data;
				this.foreignDocs	= response[4].data;
				this.training		= response[5].data;
				this.certificates	= response[6].data;
				this.visa			= response[7].data;
			});
		},
		getEducation () {
			return this.$http.get('education',{ params:{ id: this.id } });
		},
		getFamily () {
			return this.$http.get('family',{ params:{ id: this.id } });
		},
		getEmployment () {
			return this.$http.get('employment',{ params:{ id: this.id } });
		},
		getLocaldocs () {
			return this.$http.get('localdocs',{ params:{ id: this.id } });
		},
		getForeigndocs () {
			return this.$http.get('foreigndocs',{ params:{ id: this.id } });
		},
		getTraining () {
			return this.$http.get('training',{ params:{ id: this.id } });
		},
		getCertificate () {
			return this.$http.get('certificate',{ params:{ id: this.id } });
		},
		getVisa () {
			return this.$http.get('visa',{ params:{ id: this.id } });
		},
		verifiedApplicant(){
			
			this.loadingVerified = true;
				
			setTimeout(() => {
				this.$http.post('applicants/verified', {
					params: {
						id: this.id
					}
				})
				.then((resp) => {
					if(typeof resp.data.msg === 'undefined'){
						console.log('no msg');
						this.dialog = false;
						this.profiles.find(({ USERID }) => USERID === this.id).VERIFIED_BY = 2;
						setTimeout(() => {
							this.loadingVerified = false;
						}, 200);
					}else{
						this.errorVerifiedMsg = resp.data.msg.join(', ').replace(/,([^,]*)$/, ' &' + '$1');
						this.errorVerified = true;
						console.log('with msg');
					}
				});
			}, 2000);
		},
		isValidDate(dateString) {
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
	},
	watch: {
		searchApplicant (val) {
			if(!this.searchOn) return
			
			clearTimeout(timeoutSearch);
			
			timeoutSearch = setTimeout(() => {
				this.isSearching = true;
				this.searchNoData = "Searching...";
				
				this.$http.get('autocomplete/applicants', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.applicantsOption = res.data;
				})
				.finally(() => {
					if( this.applicantsOption.length == 0 ){
						this.searchNoData = "No match found";
					}
					this.isSearching = false;
				});
			}, 500);
		},
		windowSize(newVal, oldVal){
			if( newVal != oldVal ){
				clearTimeout(timeoutWindowResize);
				timeoutWindowResize = setTimeout(() => {
					this.tableResize();
				}, 600)
			}
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'windowSize'])
	},
	created () {
		this.getApplicants();
	},
	beforeDestroy(){
		clearTimeout(timeoutTableResize);
		clearTimeout(timeoutWindowResize);
	},
}

</script>

<style scoped>

	.uppercase-input >>> input {
		text-transform: uppercase
    }
	
	>>> .v-input__icon--append-outer .mdi-check-circle-outline{
		color: #4CAF50;
	}
	
	>>> .v-input--is-disabled input {
		color: #383333 !important;
	}
	
	>>> .v-label--is-disabled {
		color: #6b6767 !important;
	}
	
	>>> .theme--light.v-text-field.v-input--is-disabled .v-input__slot::before{
		border-image: repeating-linear-gradient(#dbd9d9, #dbd9d9, #dbd9d9) 1 repeat;
	}
</style>